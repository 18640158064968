<template>
  <BreadCrumbDetail PageTitle="Liste des opérateurs " />
  <OperatorContent />
</template>

<script lang="ts">
import { defineComponent,onMounted } from "vue";
import stateStore from "../../utils/store";

import BreadCrumbDetail from "../../components/Common/BreadCrumbDetail.vue";
import OperatorContent from "../../components/Settings/OperatorContent.vue";


export default defineComponent({
  name: "OperatorPage",
  components: {
    BreadCrumbDetail,
    OperatorContent,
  },
  setup(){
    const stateStoreInstance = stateStore;
    document.body.classList.remove("sidebar-hide");
    document.body.classList.add("sidebar-show");
    onMounted(() => {
          stateStoreInstance.show = true;
        });
       return {stateStoreInstance};
  }
});
</script>